import React, { useContext, FC } from 'react'
import Img3dUprightMoving from './Img3dUprightMoving'
import { configOptionsImg3dUpright } from './Img3dUprightMoving'
import { NotInViewContext } from './AnimateScrollIntoViewport'

import svg1 from "../../images/svvgNormally/bodky1-horiz-h97-w240.svg"
import svg2 from "../../images/svvgNormally/bodky1-min.svg"
import svg3 from "../../images/svvgNormally/bodky2-min.svg"
import svg4 from "../../images/svvgNormally/bodky2-vert-w198-h545.svg"
import svg5 from "../../images/svvgNormally/bodky3-min.svg"
import svg6 from "../../images/svvgNormally/bodky4-min.svg"
import svg7 from "../../images/svvgNormally/bodky5-min.svg"
import svg8 from "../../images/svvgNormally/mySpark.svg"
import svg9 from "../../images/svvgNormally/sparkle.svg"
import svg10 from "../../images/svvgNormally/hyperbola.svg"

interface Upright3dSvgsMovingProps { }

const Upright3dSvgsMoving: FC<Upright3dSvgsMovingProps> = () => {

    const { notInView, isCountedTwice } = useContext(NotInViewContext)
    const styleKeyframesIfNotInView = (notInView && !isCountedTwice) ? { animKeyframes: "unset" } : {}

    return (
        <div>

            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsDefault,
                    ...styleKeyframesIfNotInView,
                    img: svg3,
                    imgBefore: svg4,
                    imgAfter: svg5,
                    heightBase: 40,
                    widthBase: 40,
                    ratioHeightImg: 1.0, // 0.5,
                    ratioWidthImg: 1.0, // 0.5,
                    diameterRotating: 120,
                    animDelay: 7.2,
                    animDuration: 16,
                }}
                key="p3"
            />

            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsDefault,
                    ...styleKeyframesIfNotInView,
                    img: svg6,
                    imgBefore: svg7,
                    imgAfter: svg8,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 0.95, // 0.5,
                    ratioWidthImg: 0.95, // 0.5,
                    diameterRotating: 110,
                    animDelay: 19.0,
                    animDuration: 14,
                }}
                key="p6"
            />
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsDefault,
                    ...styleKeyframesIfNotInView,
                    img: svg7,
                    imgBefore: svg8,
                    imgAfter: svg9,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 0.95, // 0.5,
                    ratioWidthImg: 0.95, // 0.5,
                    diameterRotating: 150,
                    animDelay: 20.0,
                    animDuration: 22,
                }}
                key="p7"
            />

            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsDefault,
                    ...styleKeyframesIfNotInView,
                    img: svg9,
                    imgBefore: svg10,
                    imgAfter: svg1,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 0.95, // 0.5,
                    ratioWidthImg: 0.95, // 0.5,
                    diameterRotating: 130,
                    animDelay: 15.0,
                    animDuration: 10,
                }}
                key="p9"
            />
            <Img3dUprightMoving
                options={{
                    ...configOptionsImg3dUpright.optionsDefault,
                    ...styleKeyframesIfNotInView,
                    img: svg10,
                    imgBefore: svg1,
                    imgAfter: svg2,
                    heightBase: 50,
                    widthBase: 50,
                    ratioHeightImg: 0.95, // 0.5,
                    ratioWidthImg: 0.95, // 0.5,
                    diameterRotating: 100,
                    animDelay: 21.0,
                    animDuration: 14,
                }}
                key="p10"
            />
        </div>
    )
}

export default Upright3dSvgsMoving